import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import { getQueryString } from "@basicare/common/src/Helpers/getQueryString";

export const getReferralQueryString = (
  state: RxMembershipSignUpState
): string =>
  getQueryString({
    org: state.facilityReferral?.facilityCode,
    pro: state.producerReferral?.producerCode,
    promo: state.applyPromoCode?.data?.code,
  });
